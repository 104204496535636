<!--  -->
<template>
    <div class="homecont newshouContactBox">
      <div class="headBox">
          <div class="shouhome-header">
              <div class="shouhome-header-box">
              <div class="shouhome-header-left">
                  <img src="../assets/some/logo2.png" alt="">
                  <div>JUNKO NEW ENERGY</div>
              </div>
              <div class="shouhome-header-center"></div>
              <div class="shouhome-header-newright">
                  <div class="shouhome-header-right" @click="btnFlagFun()">
                  <div :class="btnFlag == true?'menulineactive':'middleline'"></div> 
                  </div>
              </div>

              </div>
              <div class="shouhome-header-line" :class="{ 'shouhomeDownBox': btnFlag }">
              <div class="shouhome-header-line-left" :class="item.status == true ? 'newBackColor':''"
              v-for="item in navList" :key="item.id" @click="selectFun(item)">
                  <div class="shouhome-header-line-left-box">
                      {{item.name}}
                  <!-- <van-icon name="arrow" v-if="item.childList.length > 0 && item.status == false"></van-icon>
                  <van-icon name="arrow-down" v-if="item.childList.length > 0 && item.status == true"></van-icon> -->
                  </div>
                  <!-- <template v-if="item.childList.length > 0 && item.status == true">
                  <div v-for="it in item.childList" :key="it.id" @click.stop="childselectFun(item,it)"
                  class="shouhome-header-line-left-box-child"
                  :class="it.status == true ? 'newBackColor':''">{{it.name}}</div>
                  </template> -->
              </div>

              </div>
          </div>
      </div>


      <div class="shouAbout-content">
        <img v-if="categoryIdVal == '21'" class="shouAbout-content-swiperImg" src="../assets/some/20.jpg" />
        <img v-else class="shouAbout-content-swiperImg" src="../assets/some/18.png" />
      </div>

      <div class="shouChan-box">
        <div class="shouChan-box-title">Product Center</div>
        <div class="shouChan-box-callparms">
            <el-collapse v-model="activeNames" @change="fuChangeFun" accordion>
                <el-collapse-item title="Equipment" name="1" class="aaaa" >
                    <el-collapse>
                        <el-collapse-item v-for="(item,index) in datalist" :key="index" :title="item.name" :name="item.id" >
                            <div class="newhomeChanLftsall" v-for="(it,index) in item.list" :key="index"
                            @click="handleChangeChild(it.id)" :class="it.status == true ? 'ceshiBaccolor':''">{{it.name}}</div>
                        </el-collapse-item>
                    </el-collapse>
                </el-collapse-item>
                <el-collapse-item title="SIC" name="2" class="aaaa">
                    <div class="newhomeChanLftsall newBox" @click="handleChangeChild('21')">High-purity Conductive SIC powder</div>
                </el-collapse-item>
            </el-collapse>
        </div>
      </div>
      <div class="shouhome-footer newshouChan">
        <div class="shouhome-footer-line">
          <div  v-for="item in tableList" :key="item.id" class="shouhome-footer-line-left" @click="seeDetailsfun(item)">
            <img :src="item.imgsUrl[0]" alt="">
            <div class="shouhome-footer-line-left-title">{{item.title}}</div>
            <!-- <div class="shouhome-footer-line-left-text">{{item.productText}}</div> -->
            <div class="shouhome-footer-line-left-btn">See more <i class="el-icon-arrow-right"></i></div>
          </div>
        </div>
      </div>
      
      <!-- <div class="homeWoBox newshouContact">
        <div class="homeWoBox-title">Request for Quotation</div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="95px" class="demo-ruleForm">
            <el-form-item label="Name" prop="name">
                <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="Mobile" prop="phone">
                <el-input v-model="ruleForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="Email" prop="email">
                <el-input v-model="ruleForm.email"></el-input>
            </el-form-item>
            <el-form-item label="Country" prop="country">
                <el-input v-model="ruleForm.country"></el-input>
            </el-form-item>
            <el-form-item label="Leaving a message" prop="message" class="ceshidiv">
                <el-input v-model="ruleForm.message" type="textarea" :rows="2"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')">Submit</el-button>
            </el-form-item>
        </el-form>
      </div> -->

        <div class="shouhome-footer">
          <div class="shouhome-footer-box newApplication"> | Request for Quotation </div>
          <div class="shouhome-footer-message">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="84px" class="demo-ruleForm">
                <el-form-item label="Name" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="Mobile" prop="phone">
                    <el-input v-model="ruleForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="Email" prop="email">
                    <el-input v-model="ruleForm.email"></el-input>
                </el-form-item>
                <el-form-item label="Country" prop="country">
                    <el-input v-model="ruleForm.country"></el-input>
                </el-form-item>
                <el-form-item label="Leaving a message" prop="message" class="ceshidiv">
                    <el-input v-model="ruleForm.message" type="textarea" :rows="2"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">Submit</el-button>
                </el-form-item>
            </el-form>
          </div>
        </div>


      <Bottom></Bottom>
    </div>
  </template>
  
  <script>
    import {Dialog} from 'vant';
  import { APILogin } from "../api/APILogin";
  import Bottom from "../components/shoubottom";
  export default {
    name: "shouChan",
    components: {

      Bottom,
    },
    data() {
      return {
        activeNames: ['1'],
        listShow: true, // 列表详情切换
        total: 0,
        currentPage: 1,
        pageSize: 100000,
        tableList: [],
        categoryIdVal: '',
        contentDetails: '',
        stateVal: '',



        datalist: [
            {
                id: '1',
                name: 'Induction heating power supply',
                list: [
                    {
                        id: '01',
                        name: 'MF/super audio Universal series resonance power supply',
                        status: false,
                    },{
                        id: '02',
                        name: 'High precision series resonance MF/super audio/Ultra high frequency power supply',
                        status: false,
                    },{
                        id: '03',
                        name: 'High precision series resonance all in one /HHT power supply',
                        status: false,
                    },{
                        id: '04',
                        name: 'High precision parallel resonance power supply',
                        status: false,
                    },{
                        id: '05',
                        name: 'Special induction power supply',
                        status: false,
                    },{
                        id: '06',
                        name: 'Synchronous Dual Frequency Induction Heating Machine',
                        status: false,
                    },{
                        id: '07',
                        name: 'Portable Air cooling Induction Heating Machine',
                        status: false,
                    },{
                        id: '08',
                        name: 'Portable Induction Heating Machine',
                        status: false,
                    },{
                        id: '09',
                        name: 'Crystal Growth Induction Heating Machine',
                        status: false,
                    },
                ]
            }, 
            {
                id: '2',
                name: 'CNC quenching machine tool',
                list: [
                    {
                        id: '021',
                        name: 'Vertical CNC hardening machine tool',
                        status: false,
                    },{
                        id: '022',
                        name: 'Disk CNC quenching machine tool',
                        status: false,
                    },{
                        id: '023',
                        name: 'Horizontal hardening machine too',
                        status: false,
                    },{
                        id: '024',
                        name: 'Raceway CNC Quenching Machine for Slewing rings',
                        status: false,
                    },{
                        id: '025',
                        name: 'Whole Teeth Induction Hardening Machine',
                        status: false,
                    },
                ]
            },
            {
                id: '3',
                name: 'Water cooling system',
                list: [
                    {
                        id: '031',
                        name: 'Water cooling system',
                        status: false,
                    },{
                        id: '032',
                        name: 'Industrial water chiller',
                        status: false,
                    },
                ]
            },
            {
                id: '4',
                name: 'Transformer',
                list: [
                    {
                        id: '041',
                        name: 'Handheld transformer',
                        status: false,
                    },{
                        id: '042',
                        name: 'Coaxial transformer',
                        status: false,
                    },
                ]
            },
            {
                id: '5',
                name: 'Auxiliary equipment',
                list: [
                    {
                        id: '051',
                        name: 'H-RB series laser fiber temperature sensor',
                        status: false,
                    },{
                        id: '052',
                        name: 'Energy Monitoring system',
                        status: false,
                    },
                ]
            },
        ],



        ruleForm: {
          name: '',
          country: '',
          email: '',
          message: '',
          phone: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入名称', trigger: 'blur' },
          ],
          phone: [
            { required: true, message: '请输入电话', trigger: 'blur' },
          ],
          email: [
            { required: true, message: '请输入邮箱', trigger: 'blur' },
          ],
          message: [
            { required: true, message: '请输入留言', trigger: 'blur' },
          ],
        },



        btnFlag: false,
        navList: [
            {
                id: 1,
                name: 'Home',
                status: false,
                url: require('@/assets/shouji/1.png'),
                urls: require('@/assets/shouji/2.png'),
                childList: [],
                path:'/shouhome',
            },
            {
                id: 2,
                name: 'About Us',
                status: false,
                url: require('@/assets/shouji/3.png'),
                urls: require('@/assets/shouji/4.png'),
                childList: [],
                path:'/shouAbout',
            },
            {
                id: 3,
                name: 'Product Center',
                status: true,
                url: require('@/assets/shouji/5.png'),
                urls: require('@/assets/shouji/6.png'),
                path:'/shouChan',
                childList: [
                    {
                        id: 31,
                        name: 'Equipment',
                        status: true,
                        path:'/shouChan',
                    },{
                        id: 32,
                        name: 'SIC',
                        status: false,
                        path:'/shouChan',
                    }
                ],
            }, {
                id: 4,
                name: 'Application Cases',
                status: false,
                url: require('@/assets/shouji/7.png'),
                urls: require('@/assets/shouji/8.png'),
                childList: [],
                path:'/shouYing',
            },{
                id: 5,
                name: 'Contact Us',
                status: false,
                url: require('@/assets/shouji/9.png'),
                urls: require('@/assets/shouji/10.png'),
                childList: [],
                path:'/shouContact',
            },
        ],

      }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    filters: {},
    //方法集合
    methods: {
        // 选择事件
        fuChangeFun(val) {
            var newval = val.toString()
            //console.log("选择事件>>>>>>>>>>",JSON.stringify(newval))
            if(newval == '') {
                this.categoryIdVal = ''
                this.listShow = true
                this.currentPage = 1
                this.pageSize = 10
                this.tableList = []
                this.getDataList()
            }

        },
        handleChangeChild(val) {
            //console.log("2222>>>>>>",JSON.stringify(val));
            this.datalist.map(item=>{
                item.list.map(it=>{
                    if(val == it.id) {
                        it.status = true
                    }else {
                        it.status = false
                    }
                })
            })
            this.listShow = true
            this.categoryIdVal = val
            this.currentPage = 1
            this.pageSize = 10
            this.tableList = []
            this.getDataList()
        },
        // 获取产品列表数据
        getDataList() {
            let a = typeof(this.categoryIdVal)
            //console.log(">>>>>>>>>>",a)
            if(a == 'object') {
                this.categoryIdVal = this.categoryIdVal.toString()
            }
            APILogin.getChanList({
                page: this.currentPage, // 分页参几页
                limit: this.pageSize, // 分页参每页几条
                categoryId: this.categoryIdVal, // 分类id
            }).then(res => {
                let b = res.data.data
                b.map(item=>{
                    item.imgsUrl = item.imgs.split(',')
                })
                this.tableList = b
                this.total = res.data.count
            }).catch(fail => {
                console.log("fail>>>>" + JSON.stringify(fail))
            })
        },
        // 查看详情
        seeDetailsfun(item) {
            this.$router.push({
                path: '/shouChanDetails',
                query: {
                    id: item.id,
                    categoryIdVal: item.categoryId,
                }
            })
        },

      // 菜单折叠
      btnFlagFun() {
          this.btnFlag = !this.btnFlag
      },
      // 选择菜单
      selectFun(item) {
          //console.log('111>>'+ JSON.stringify(item))
          this.navList.map(it => {
              if (it.id == item.id) {
                  it.status = true
                  this.$router.push(it.path)
              }else{
                  it.status = false
              }
          })
      },
      // 子选择菜单
      childselectFun(val,obj) {
          //console.log('222>>'+ JSON.stringify(val))
          //console.log('333>>'+ JSON.stringify(obj))
          this.navList.map(item => {
              if (item.id == val.id && item.childList.length > 0) {
                  item.status = true
                  item.childList.map(it=>{
                      if(it.id == obj.id) {
                          it.status = true
                          this.$router.push(it.path)
                      }else {
                          it.status = false
                      }
                  })
              }else{
                  item.status = false
              }
          })
      },
      // 提交留言
      submitForm() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            // 验证通过，提交表单
            //console.log('提交的数据：', this.ruleForm);
            APILogin.messageAdd({
                name: this.ruleForm.name, 
                country: this.ruleForm.country,
                email: this.ruleForm.email, 
                message: this.ruleForm.message,
                phone: this.ruleForm.phone, 
            }).then(res => {
                Dialog.alert({
                    message: 'Thank you for your message. Someone will contact you later',
                })
                console.log("res>>>>" + JSON.stringify(res.data))
            }).catch(fail => {
                console.log("fail>>>>" + JSON.stringify(fail))
            }) 
          } else {
            // 验证失败
            console.log('验证失败！');
            return false;
          }
        });
      },
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
      document.title = "产品中心"
      this.stateVal = this.$route.query.state || ''
        //console.log("纯子能源11>>>>>>>>>>",JSON.stringify(this.stateVal) ) 
        // 如果是2就用sic的分类id去查数据，如果不是就用默认第一个分类去查数据 21 01
        if(this.stateVal == '2') {
            this.categoryIdVal = '21'
            this.activeNames = []
        }else if(this.stateVal == '1') {
            this.categoryIdVal = ''
            this.activeNames = []
        }
        this.listShow = true
        this.currentPage = 1
        this.pageSize = 10
        this.tableList = []
        this.getDataList()

        this.activeNames = []
        let a = JSON.stringify(this.stateVal)
        this.activeNames.push(a)


        // 详情过来的
        this.stateValBao = this.$route.query.typeVal || ''
        if(this.stateValBao != '') {
            this.listShow = false
            this.seeDetailsfun(this.stateValBao)
        }
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() { },
    beforeCreate() {}, // 生命周期 - 创建之前
    beforeMount() {}, // 生命周期 - 挂载之前
    beforeUpdate() {}, // 生命周期 - 更新之前
    updated() {}, // 生命周期 - 更新之后
    beforeDestroy() {}, // 生命周期 - 销毁之前
    destroyed() {}, // 生命周期 - 销毁完成
    activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
  };
  </script>
  <style lang="less" scoped src="@/styles/pc/home.less"></style>
    <style lang="less" scoped src="@/styles/pc/homewe.less"></style>
  <style lang="less" scoped src="@/styles/phone/shouhome.less"></style>
  <style lang="less" scoped src="@/styles/phone/shouAbout.less"></style>
  <style lang="less" scoped src="@/styles/phone/shouContact.less"></style>
  <style lang="less" scoped src="@/styles/phone/shouChan.less"></style>
  <style lang="less">
        .el-button--primary {
      width: 100%
    }
      .ceshidiv {
      .el-form-item__label {
        line-height: 16px;
        margin-top: 10px;
      }
    }
  .newshouContactBox {
    .el-button--primary {
      width: 100%
    }
  }
    .zanweshu {
        padding: 100px;
        text-align: center;
        font-size: 24px;
        color: rgba(0, 0, 0, .5);
    }
  .homeChanRigDetailsBtm-btm {
    img {
        margin: 10px 0;
        max-width: 100% !important;
    }
    video {
        width: 100% !important;
        margin: 10px 0;
    }
  }
    .shouChan-box-callparms {
        .el-collapse-item__header {
            font-size: 14px;
        }
        .el-collapse-item__header {
            font-weight: 600 !important;
            padding-left: 10px;
        }
        .el-collapse {
            border: 1px solid #EBEEF5;
        }
        .aaaa>div>div {
            background-color: #409EFF;
            border: solid 1px rgba(0, 0, 0, .1);
            font-size: 20px;
            color: #fff;
        }
        .el-collapse-item__content {
            padding-bottom: 0 !important
        }
    }

    .ceshiBaccolor {
        color: #409EFF;
        font-weight: 600;
    }

  </style>
  
  
  
  